import { useEffect, useRef, useState } from "react";
import { delay, motion } from "framer-motion";
import "./App.css";
import {
  BlackTitle,
  BoldTitle,
  Circle,
  Container,
  DesktopNav,
  FlowIcon,
  ImageContainer,
  IndicatorLine,
  IndicatorLineContainer,
  Left,
  LineTitleHolder,
  Logo,
  LogoHandler,
  MainFooter,
  MobileNav,
  MobileNavToggle,
  MockUpImg,
  MockUpImgBottom,
  NavBar,
  NavBarItem,
  NavHeader,
  Right,
  ScrollLine,
  Slogan,
  SubBlackTitle,
  SubContainer,
  TopTitle,
  UnderLined,
} from "./styled/main.style";
import {
  Left1,
  Left2,
  Left3,
  Left4,
  Left5,
  Left6,
  Right1,
  Right2,
  Right3,
  Right4,
  Right5,
  Right6,
} from "./components/slogans";

const SLOGAN_NUM = 6;
const SCROLL_HEIGHT = 600;
const THRESTHold = 0.2;
const SLOGON_LENGTH_ARR = Array.from(
  { length: SLOGAN_NUM - 1 },
  (_, index) => index
);

export const AnimatedPaveros = () => (
  <>
    <motion.span
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.25,
        delay: 1 / 10,
      }}
      style={{ color: "#091124" }}
    >
      P
    </motion.span>
    <motion.span
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.25,
        delay: 2 / 10,
      }}
      style={{ color: "#2C2C50" }}
    >
      A
    </motion.span>
    <motion.span
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.25,
        delay: 3 / 10,
      }}
      style={{ color: "#095DAD" }}
    >
      V
    </motion.span>
    <motion.span
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.25,
        delay: 4 / 10,
      }}
      n
      style={{ color: "#63241B" }}
    >
      e
    </motion.span>
    <motion.span
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.25,
        delay: 5 / 10,
      }}
      style={{ color: "#ECB136" }}
    >
      R
    </motion.span>
    <motion.span
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.25,
        delay: 6 / 10,
      }}
      style={{ color: "#000" }}
    >
      O
    </motion.span>
    <motion.span
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.25,
        delay: 7 / 10,
      }}
      style={{ color: "#DACD00" }}
    >
      S
    </motion.span>
  </>
);

function App() {
  const [wheelDelta, setWheelDelta] = useState(0);
  const [ClientStartY, setClientStartY] = useState(0);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleTouch = (event) => {
    const touchDeltaY = (ClientStartY - event.touches[0].clientY) * THRESTHold;
    console.log(touchDeltaY);
    setWheelDelta((prev) => {
      const nextValue = Math.max(
        0,
        Math.min(SLOGAN_NUM * SCROLL_HEIGHT, prev + touchDeltaY)
      );
      return nextValue;
    });

    setWidth(
      vw.current
        ? mapValue(
            wheelDelta,
            0,
            SLOGAN_NUM * SCROLL_HEIGHT,
            0,
            vw.current.offsetWidth * 0.8
          )
        : 0
    );
  };

  const handleWheel = (event) => {
    setWheelDelta((prev) => {
      const nextValue = Math.max(
        0,
        Math.min(SLOGAN_NUM * SCROLL_HEIGHT, prev + event.deltaY)
      );
      return nextValue;
    });

    setWidth(
      vw.current
        ? mapValue(
            wheelDelta,
            0,
            SLOGAN_NUM * SCROLL_HEIGHT,
            0,
            vw.current.offsetWidth * 0.8
          )
        : 0
    );
  };

  const getComponentByScrollLeft = () => {
    const scrollIndex = Math.floor(wheelDelta / SCROLL_HEIGHT);

    switch (scrollIndex) {
      case 0:
        return <Left1 />;
      case 1:
        return <Left2 />;
      case 2:
        return <Left3 />;
      case 3:
        return <Left4 />;
      case 4:
        return <Left5 />;
      case 5:
        return <Left6 />;
      // Add more cases as needed

      default:
        return <Left6 />;
    }
  };
  const getComponentByScrollRight = () => {
    const scrollIndex = Math.floor(wheelDelta / SCROLL_HEIGHT);

    switch (scrollIndex) {
      case 0:
        return <Right1 />;
      case 1:
        return <Right2 />;
      case 2:
        return <Right3 />;
      case 3:
        return <Right4 />;
      case 4:
        return <Right5 />;
      case 5:
        return <Right6 />;
      // Add more cases as needed

      default:
        return <Right6 />;
    }
  };

  // Framer motion configuration
  const Item = {
    hidden: {
      scale: 0,
    },
    show: {
      scale: 1,
      transition: {
        type: "spring",
        duration: 0.5,
      },
    },
  };

  // const [isScrolling, setIsScrolling] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (!isScrolling) {
  //       setIsScrolling(true);

  //       // Set a timeout to reset isScrolling after a delay (e.g., 500 milliseconds)
  //       setTimeout(() => {
  //         setIsScrolling(false);
  //       }, 500);
  //     }
  //   };

  //   // Add event listener to the 'wheel' event
  //   window.addEventListener("wheel", handleScroll);

  //   // Clean up the event listener when the component is unmounted
  //   return () => {
  //     window.removeEventListener("wheel", handleScroll);
  //   };
  // }, [isScrolling]);

  const getComponentByScrollTopTitle = () => {
    const scrollIndex = Math.floor(wheelDelta / SCROLL_HEIGHT);

    switch (scrollIndex) {
      case 0:
        return <BlackTitle>{AnimatedPaveros()}</BlackTitle>;
      case 1:
        return (
          <SubBlackTitle>
            {AnimatedPaveros()}
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
              style={{ color: "#095DAD" }}
            >
              {" "}
              Interface
            </motion.span>
          </SubBlackTitle>
        );
      case 3:
        return (
          <SubBlackTitle>
            {AnimatedPaveros()}
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
              style={{ color: "#095DAD" }}
            >
              {" "}
              Server
            </motion.span>
          </SubBlackTitle>
        );
      case 2:
        return (
          <SubBlackTitle>
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
              style={{ color: "#091124" }}
            >
              Ce n'est pas une simple <UnderLined>SCADA</UnderLined>
            </motion.span>
          </SubBlackTitle>
        );
      case 4:
        return (
          <SubBlackTitle>
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
              style={{ color: "#091124" }}
            >
              Ce n'est pas un simple <UnderLined>Serveur</UnderLined>
            </motion.span>
          </SubBlackTitle>
        );
      case 5:
        return (
          <SubBlackTitle>
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
              style={{ color: "#091124" }}
            >
              Vous <UnderLined>recherchez</UnderLined> plus d'informations?
            </motion.span>
          </SubBlackTitle>
        );
      // Add more cases as needed

      default:
        return (
          <SubBlackTitle>
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
              style={{ color: "#091124" }}
            >
              Vous <UnderLined>recherchez</UnderLined> plus d'informations?
            </motion.span>
          </SubBlackTitle>
        );
    }
  };

  const mapValue = (value, inputMin, inputMax, outputMin, outputMax) => {
    return (
      ((value - inputMin) / (inputMax - inputMin)) * (outputMax - outputMin) +
      outputMin
    );
  };

  const vw = useRef();
  const [width, setWidth] = useState(0);
  return (
    <Container>
      <NavHeader>
        <LogoHandler>
          <Logo height="50" src="/images/logopaveros.png" />
          <Logo height="30" src="/images/logooussama.png" />
        </LogoHandler>
        <NavBar>
          <NavBarItem
            onClick={() =>
              window.open("https://www.oussama.ma/qui-sommes-nous/", "_blank")
            }
          >
            À PROPOS
          </NavBarItem>
          <NavBarItem
            onClick={() =>
              window.open("https://www.oussama.ma/contact/", "_blank")
            }
          >
            CONTACT
          </NavBarItem>
        </NavBar>
      </NavHeader>
      <SubContainer
        ref={vw}
        onWheel={handleWheel}
        onTouchStart={(e) => setClientStartY(e.touches[0].clientY)}
        onTouchMove={handleTouch}
      >
        <Left>{getComponentByScrollLeft()}</Left>
        <Right>{getComponentByScrollRight()}</Right>
        <IndicatorLine width={width}>
          {SLOGON_LENGTH_ARR.map((item, index) => {
            return (
              <Circle
                key={index}
                active={Math.floor(wheelDelta / SCROLL_HEIGHT) === index}
              />
            );
          })}
        </IndicatorLine>

        <TopTitle>{getComponentByScrollTopTitle()}</TopTitle>
        <ScrollLine>
          <p>JUST</p>
          <p>SCROLL</p>
        </ScrollLine>
      </SubContainer>
    </Container>
  );
}

export default App;
