import { AnimatedPaveros } from "../App";
import {
  Action,
  ActionHolder,
  ArrowImg,
  BlackTitle,
  BoldTitle,
  ImageContainer,
  Line,
  LogoImage,
  MockUpImg,
  MockUpImgBottom,
  MockUpImgBottomRight,
  MockUpImgCenter,
  RegularTitle,
  Slogan,
  SubBlackTitle,
} from "../styled/main.style";
import Typewriter from "typewriter-effect";

const _transition = {
  duration: 0.5,
  ease: [0, 0.71, 0.2, 1.01],
};

const _animation = { opacity: 1, x: "-50%", y: "-50%", scale: 1 };

export const Left1 = () => (
  <Slogan
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{
      duration: 0.8,
      delay: 0.7,
      ease: [0, 0.71, 0.2, 1.01],
    }}
  >
    <BoldTitle>
      Telegestion évoluée, SCADA intelligent, analyse de données avancée : la
      clarté à travers une visualisation puissante.
    </BoldTitle>
    <ActionHolder>
      <Action onClick={() => window.open("https://www.oussama.ma/", "_blank")}>
        <h3>Visitez notre site web </h3>
      </Action>
      <ArrowImg src="/images/arrow.png" />
    </ActionHolder>
  </Slogan>
);

export const Left2 = () => (
  <Slogan
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{
      duration: 0.8,
      delay: 0.5,
      ease: [0, 0.71, 0.2, 1.01],
    }}
  >
    <BoldTitle>
      SCADA novateur, application web responsive : Analyse de données dynamique,
      visualisation éclatante, une solution tout en couleurs.
    </BoldTitle>
  </Slogan>
);
export const Left3 = () => (
  <Slogan
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{
      duration: 0.8,
      delay: 0.5,
      ease: [0, 0.71, 0.2, 1.01],
    }}
  >
    <BoldTitle>
      {AnimatedPaveros()}
      <span style={{ color: "#095DAD" }}> Interface</span>,
    </BoldTitle>
    <RegularTitle>
      Bien plus qu'une SCADA classique. Avec une application web responsive,
      elle offre une visualisation des données colorées, trace des courbes sur
      mesure, génère des rapports précis, et crée un historique visuel sur des
      tableaux interactifs.
    </RegularTitle>
  </Slogan>
);
export const Left4 = () => (
  <Slogan
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{
      duration: 0.8,
      delay: 0.5,
      ease: [0, 0.71, 0.2, 1.01],
    }}
  >
    <BoldTitle>
      Votre serveur desktop tout-en-un, en marche 24/7. Stockage, analyse,
      alarmes sécurisées, redondance des données, création de comptes sécurisés,
      le tout dans un logiciel puissant.
    </BoldTitle>
  </Slogan>
);
export const Left5 = () => (
  <Slogan
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{
      duration: 0.8,
      delay: 0.5,
      ease: [0, 0.71, 0.2, 1.01],
    }}
  >
    <BoldTitle>
      {AnimatedPaveros()}
      <span style={{ color: "#095DAD" }}> Server</span>,
    </BoldTitle>
    <RegularTitle>
      Est une application desktop hébergeant un serveur UDP sécurisé,
      opérationnelle 24/7. Elle reçoit, analyse, stocke les données, gère les
      alarmes et assure une gestion sécurisée des comptes utilisateurs, tout en
      maintenant une liaison permanente avec la SCADA{" "}
      <strong>Paveros Interface</strong> pour une intégration fluide et
      efficace.
    </RegularTitle>
  </Slogan>
);
export const Left6 = () => (
  <ImageContainer>
    <MockUpImg
      alt="Paveros"
      initial={{ opacity: 0, x: 1000, y: -1000 }}
      animate={_animation}
      transition={{ ..._transition, delay: 0 }}
      src="/images/01.png"
    />
    <MockUpImg
      alt="Paveros"
      initial={{ opacity: 0, x: -1000, y: -1000 }}
      animate={_animation}
      transition={{ ..._transition, delay: 0.1 }}
      src="/images/02.png"
    />
    <MockUpImg
      alt="Paveros"
      initial={{ opacity: 0, x: 1000, y: 1000 }}
      animate={_animation}
      transition={{ ..._transition, delay: 0.3 }}
      src="/images/03.png"
    />
    <MockUpImg
      alt="Paveros"
      initial={{ opacity: 0, x: -1000, y: 1000 }}
      animate={_animation}
      transition={{ ..._transition, delay: 0.2 }}
      src="/images/04.png"
    />
  </ImageContainer>
);

export const Right1 = () => (
  <ImageContainer>
    <MockUpImgBottom
      transition={{
        delay: 0.7,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      src="/images/mockup.png"
      alt="Paveros"
    />
  </ImageContainer>
);

export const Right2 = () => (
  <ImageContainer>
    <MockUpImg
      initial={{ opacity: 0, scale: 0 }}
      animate={_animation}
      transition={{ ..._transition, delay: 0 }}
      src="/images/pav01.png"
      alt="Paveros"
    />
    <MockUpImg
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ..._transition, delay: 0.5 }}
      src="/images/pav02.png"
      alt="Paveros"
    />
    <MockUpImg
      initial={{ opacity: 0, scale: 0 }}
      animate={_animation}
      transition={{ ..._transition, delay: 1 }}
      src="/images/pav03.png"
      alt="Paveros"
    />
    <MockUpImg
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ..._transition, delay: 1.5 }}
      src="/images/pav04.png"
      alt="Paveros"
    />
  </ImageContainer>
);
export const Right3 = () => (
  <ImageContainer>
    <MockUpImgCenter
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ..._transition, delay: 2.3 }}
      src="/images/pav000.png"
      alt="Paveros"
    />
    <MockUpImgCenter
      initial={{ opacity: 0, x: -1000 }}
      animate={_animation}
      transition={{ ..._transition, delay: 1 }}
      src="/images/pav001.png"
      alt="Paveros"
    />
    <MockUpImgCenter
      initial={{ opacity: 0, x: 1000 }}
      animate={_animation}
      transition={{ ..._transition, delay: 1.8 }}
      src="/images/pav002.png"
      alt="Paveros"
    />
  </ImageContainer>
);
export const Right4 = () => (
  <ImageContainer>
    <MockUpImgCenter
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ..._transition, delay: 0.5 }}
      src="/images/server00.png"
      alt="Paveros"
    />
    <MockUpImgCenter
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ..._transition, delay: 0.5 }}
      src="/images/server01.png"
      alt="Paveros"
    />
    <MockUpImgCenter
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ..._transition, delay: 1 }}
      src="/images/server02.png"
      alt="Paveros"
    />
    <MockUpImgCenter
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ..._transition, delay: 1.5 }}
      src="/images/server03.png"
      alt="Paveros"
    />
  </ImageContainer>
);

export const Right5 = () => (
  <ImageContainer>
    <MockUpImgBottomRight src="/images/serverDesk.png" alt="Paveros" />
  </ImageContainer>
);
export const Right6 = () => (
  <Slogan
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{
      duration: 0.8,
      delay: 0.7,
      ease: [0, 0.71, 0.2, 1.01],
    }}
  >
    <BoldTitle>
      Intéressé(e) à en savoir plus sur {AnimatedPaveros()}?
    </BoldTitle>
    <Line />
    <RegularTitle>
      Nos équipes sont à votre disposition pour vous présenter notre solution.
    </RegularTitle>
    <ActionHolder>
      <Action
        onClick={() => window.open("https://www.oussama.ma/contact/", "_blank")}
      >
        <h3>Contactez-nous</h3>
      </Action>
      <ArrowImg src="/images/arrow.png" alt="Paveros" />
    </ActionHolder>
    {/* <BoldTitle>
      Des données fluides pour une gestion impeccable : notre solution, votre
      eau, notre engagement.
    </BoldTitle> */}
  </Slogan>
);
