import { createGlobalStyle } from "styled-components";

export const base = {
  space: ["0px", "2px", "4px", "8px", "16px", "32px", "64px"],
  fonts: {
    heading: "Inter, system-ui, sans-serif",
    body: "Inter, system-ui, sans-serif",
  },
  fontSizes: ["12px", "14px", "16px", "20px", "24px"],
};

const sizeW = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "480px",
  mobileXL: "540px",
  tablet: "768px",
  ipad: "811px",
  laptop: "1024px",
  laptopS: "1290px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const DeviceSize = {
  mobileS: `(min-width: ${sizeW.mobileS})`,
  mobileM: `(min-width: ${sizeW.mobileM})`,
  mobileL: `(min-width: ${sizeW.mobileL})`,
  mobileXL: `(min-width: ${sizeW.mobileXL})`,
  tablet: `(min-width: ${sizeW.tablet})`,
  ipad: `(min-width: ${sizeW.ipad})`,
  laptop: `(min-width: ${sizeW.laptop})`,
  laptopL: `(min-width: ${sizeW.laptopL})`,
  desktop: `(min-width: ${sizeW.desktop})`,
  desktopL: `(min-width: ${sizeW.desktop})`,
};

export const colors = {
  body: "#FFFFFF",
  text: "#091124",
  color1: "#2C2C50",
  color2: "#63241B",
  color3: "#DACD00",
  color4: "#095DAD",
  success: "#5cb85c",
  warning: "#cc3300",
  color5: "#ECB136",
};
export const GlobalStyles = createGlobalStyle`
  
  /* Firefox */
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
  @import url('https://fonts.cdnfonts.com/css/mona-sans');
  body {
    background-image: url("/images/globalBg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  height: 100%;
  width:100%;
  }
  html,#root{
    height: 100%;
  width:100%;
  position: fixed;
  }
  * {
    font-family: "Mona-Sans" , sans-serif;
    scrollbar-color: rgba(10, 24, 58, 1) #f1f1f1; /* color of the thumb and track */
    scrollbar-width: thin; 
    margin: 0;
    padding: 0;
    color: "#091124";
    
}`;
