import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import { DeviceSize } from "../config/theming.style";

export const Container = styled.div`
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: 100%;
  background-image: url("/images/oussamaIcongray.png");
  background-size: auto;
  background-repeat: repeat;
  background-position: center center;
`;
export const NavHeader = styled.div`
  padding: 10;
  height: 100px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${DeviceSize.ipad} {
    width: 80%;
    height: 70px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const LogoHandler = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
export const NavBar = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;
export const NavBarItem = styled.div`
  font-weight: 800;
  cursor: pointer;
  color: #091124;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.1);
    color: #095dad;
  }
  font-size: 0.8rem;
  @media ${DeviceSize.ipad} {
    font-size: 1.2rem;
  }
`;
export const Logo = styled.img`
  height: ${(props) => Number(props.height) * 0.8}px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${DeviceSize.ipad} {
    height: ${(props) => props.height}px;
  }
`;

export const SubContainer = styled.div`
  height: calc(100% - 100px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-content: center;
  position: relative;
  @media ${DeviceSize.ipad} {
    flex-direction: row;
    padding-top: 100px;
    height: calc(100% - 50px);
    padding-top: 0;
  }
`;
export const Left = styled.div`
  height: calc(50% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${DeviceSize.ipad} {
    flex: 1;
    height: 100%;
  }
`;
export const Right = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  order: 1;
  height: 50%;
  @media ${DeviceSize.ipad} {
    flex: 1;
    height: 100%;
  }
`;

export const Slogan = styled(motion.div)`
  width: 80%;
  background-color: ${(props) => props.color};
`;

export const IndicatorLine = styled.div`
  position: absolute;
  top: 0px;
  left: 10%;
  height: 6px;
  border-top: 2px solid black;
  width: calc(80% + 2px);
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  &::after {
    content: "";
    display: block;
    width: 10px;
    height: 4px;
    position: absolute;
    top: 0px;
    left: 1px;
    background-color: #095dad;
    border-radius: 0 0 10px 10px;
    width: ${(props) => (props.width ? props.width : 0)}px;
  }
`;
export const Circle = styled.div`
  width: ${(props) => (props.active ? 5 : 3)}px;
  height: ${(props) => (props.active ? 10 : 5)}px;
  margin-top: ${(props) => (props.active ? 0 : 10)}px;
  border-radius: 20px;
  background-color: #091124;
  transition: all 0.5s ease-in-out;
`;
export const TopTitle = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%, -30%);
  font-weight: 900;
  @media ${DeviceSize.ipad} {
    top: 30px;
    margin: 0 auto;
    font-weight: 900;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;
export const MockUpImg = styled(motion.img)`
  max-width: 90%;
  max-height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const MockUpImgBottom = styled(motion.img)`
  width: 90%;
  position: absolute;
  bottom: 0;
  right: 10px;
`;
export const MockUpImgBottomRight = styled(motion.img)`
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
`;
export const MockUpImgCenter = styled(motion.img)`
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(2px 2px 8px hsla(0deg, 0%, 0%, 0.3));
  &:after {
    content: "";
    top: 40%;
    left: 40%;
    background-color: #095dad;
    width: 100%;
    height: 100%;
  }
`;

export const BlackTitle = styled.h1`
  font-size: 2rem;
  font-weight: 900;
  letter-spacing: -0.3rem;
  @media ${DeviceSize.mobileL} {
    font-size: 2rem;
  }
  @media ${DeviceSize.ipad} {
    font-size: 6.2rem;
    letter-spacing: -0.8rem;
  }
`;
export const SubBlackTitle = styled.h1`
  font-size: 1rem;
  font-weight: 900;
  text-align: center;
  @media ${DeviceSize.mobileL} {
    font-size: 1.3rem;
  }
  @media ${DeviceSize.ipad} {
    font-size: 2.2rem;
    font-weight: 900;
  }
`;
export const UnderLined = styled.span`
  position: relative;

  &::after {
    content: "";
    height: 15px;
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 0;
    transform: rotateY(-0deg) rotateZ(-10deg);
    background: linear-gradient(
      90deg,
      rgba(236, 177, 54, 1) 0%,
      rgba(189, 140, 36, 1) 100%
    );
    border-radius: 10px;
  }

  @media ${DeviceSize.ipad} {
    &::after {
      content: "";
      height: 30px;
      width: 100%;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 0;
      transform: rotateY(-30deg) rotateZ(-10deg);
      background: linear-gradient(
        90deg,
        rgba(236, 177, 54, 1) 0%,
        rgba(189, 140, 36, 1) 100%
      );
      border-radius: 10px;
    }
  }
`;

const moveBottom = keyframes`
  0% {
    bottom: 20px;
  }
  50% {
    bottom: 30px;
  }
  100% {
    bottom: 20px;
  }
`;
const moveGap = keyframes`
  0% {
    gap: 20px;
  }
  50% {
    gap: 40px;
  }
  100% {
    gap: 20px;
  }
`;

export const ScrollLine = styled.span`
  display: none;
  & > p {
    font-size: 0.5rem;
  }
  @media ${DeviceSize.ipad} {
    display: block;
    position: absolute;
    font-size: 0.7rem;
    font-weight: 900;
    left: 40px;
    animation: ${moveBottom} 2s infinite;
    &::after {
      content: "";
      position: absolute;
      bottom: -30px;
      left: -10px;
      height: 60px;
      width: 2px;
      border-radius: 10px;
      background-color: #091124;
    }
  }
`;
export const BoldTitle = styled.h1`
  font-size: 1.1rem;
  font-weight: 800;
  @media ${DeviceSize.ipad} {
    font-size: 1.6rem;
  }
  @media ${DeviceSize.mobileL} {
    font-size: 1.2rem;
  }
`;
export const Line = styled.div`
  height: 2px;
  width: 90%;
  border-radius: 19px;
  margin: 20px 0;
  background-color: #091124;
`;
export const ActionHolder = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 20px 0;
  gap: 40px;
  animation: ${moveGap} 2s infinite;
`;
export const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 10;
  & > h3 {
    font-weight: 900;
    font-size: 1rem;
  }
  @media ${DeviceSize.ipad} {
    & > h3 {
      font-size: 1.2rem;
    }
  }
  transition: all 0.5 ease;

  &:hover {
    &::after {
      transform: rotateY(0deg) rotateZ(0deg);
    }
  }
  &::after {
    content: "";
    height: 20px;
    width: 100%;
    position: absolute;
    transition: all 0.5 ease;
    z-index: -1;
    top: 50%;
    left: 0;
    transform: rotateY(-0deg) rotateZ(-5deg);
    background: linear-gradient(
      90deg,
      rgba(236, 177, 54, 1) 0%,
      rgba(189, 140, 36, 1) 100%
    );
    border-radius: 17px;
  }
`;
export const ArrowImg = styled.img`
  height: 25px;
`;

export const RegularTitle = styled.h1`
  font-size: 1rem;
  font-weight: 400;

  @media ${DeviceSize.mobileL} {
    font-size: 1.4rem;
    margin: 20px 0;
  }
  @media ${DeviceSize.ipad} {
    font-size: 1.6rem;
    margin: 20px 0;
  }
`;

export const LogoImage = styled.img`
  height: 30px;
`;
